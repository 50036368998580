<template>
    <b-row>
        <b-col cols="12">
            <template>
                <!-- <div class="card">
          <b-card-body> </b-card-body>
        </div> -->
                <div class="card">
                    <div class="p-2 d-flex flex-wrap justify-content-between">
                        <div class="info-left w-30">
                            <h3>Top {{ chartSelectedText }}</h3>
                            <h5>
                                {{ countryName }} 
                                <span v-if="updated_At"> | Last Update : 
                                    <span >
                                        {{ moment(updated_At).format("MMM DD, YYYY HH:mm:ss") }}
                                    </span>
                                </span>
                            </h5>
                        </div>
                        <div class="d-flex flex-column flex-md-row w-70 w-md-100" style="gap: 5px">
                            <b-form-group class="mb-0">
                                <b-form-select :options="optionsChart" v-model="chartSelected">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group class="mb-0">
                                <b-form-select :options="optionsType" v-model="typeSelected">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group class="mb-0">
                                <b-form-select :options="optionsCategories" v-model="CategorySelected">
                                </b-form-select>
                            </b-form-group>
                            <b-form-group class="mb-0" style="max-width: 150px !important">
                                <b-form-select :options="optionsCountries" v-model="countryCode"></b-form-select>
                            </b-form-group>
                            <!--<b-form-group class="mb-0">
                                <b-form-datepicker right="15" v-model="date" :min="minDate" :max="maxDate"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                    }"></b-form-datepicker>
                            </b-form-group>-->
                        </div>
                    </div>

                    <!--:sort-by="sortBy"-->
                    <b-table striped hover responsive class="position-relative mb-0" :per-page="perPage"
                        :current-page="currentPage" :items="items" :fields="fields" show-empty
                        v-if="result && !progress">
                        <template #empty>
                            <div class="text-center">
                                <feather-icon icon="AlertTriangleIcon" class="" size="60" />
                                <h3 class="mt-1">Data Being Prepared</h3>
                            </div>
                        </template>
                        <template #cell(icon)="data">
                            <img  :src="data.item.icon_url" v-if="!data.item.error" 
                            @error="data.item.error = true"  width="30"  :id="'icon-' + data.index"/>
                            <div v-else>
                                <feather-icon icon="CameraOffIcon" size="30" :id="'icon-' + data.index" />
                                <b-tooltip triggers="hover" :target="'icon-' + data.index" title="Icon not found"
                                :delay="{ show: 1000, hide: 50 }"/>
                            </div>
                        </template>
                        <template #cell(score)="data">
                            {{ data.item.rating ? `${data.item.rating.toFixed(2)}/5` : 0 }}
                        </template>
                        <template #cell(title)="data">
                            <router-link style="color: inherit;" :to="{
                                name: 'apps-info',
                                params: { id: data.item.app_id, from: 'api' },
                            }" target="_blank">
                                {{ data.item.name }}
                            </router-link>
                        </template>
                        <template #cell(account)="data">
                            <router-link :to="{
                                name: 'market-manager-accounts-search',
                                query: { search: data.item.developer },
                            }" target="_blank">
                                {{ data.item.developer }}
                            </router-link>
                        </template>
                        <template #cell(max_installs)="data">
                                {{ data.item.downloads }}
                        </template>
                        <template #cell(playstoreUrl)="data">
                    <div class="d-flex" style="gap: 10px">
                        <p>
                        <a :href="`https://play.google.com/store/apps/details?id=${data.item.app_id}`" target="_blank"
                            title="Visit on Store"><feather-icon icon="EyeIcon" size="20" /></a>
                        </p>
                        <p>
                        <a :href="'/myprofile/apps/' + data.item.app_id + ',api/info'" target="_blank" title="Show More Info"
                            class="text-warning">
                            <feather-icon icon="InfoIcon" size="20" />
                        </a>
                        </p>
                    </div>
            </template>
                    </b-table>
                    <div class="text-center my-4" v-if="progress">
                        <b-spinner class="spinner" variant="primary" size="60" label="Text Centered" />
                    </div>
                    <b-row class="m-1">
                        <b-col cols="6">
                            <b-form-group class="mb-0">
                                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                                    class="w-25" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                align="center" size="sm" class="my-0" />
                        </b-col>
                    </b-row>
                </div>
            </template>
        </b-col>
    </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import moment from "moment";
import {
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormDatepicker,
    BFormCheckbox,
    BSpinner,
} from "bootstrap-vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
export default {
    components: {
        BRow,
        BCol,
        BCardCode,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BFormDatepicker,
        BFormCheckbox,
        BSpinner,
    },
    data() {
        return {
            value: "",
            moment,
            perPage: 100,
            pageOptions: [10, 50, 100, 200],
            totalRows: 1,
            currentPage: 1,
            sortBy: "released",
            filter: null,
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            fields: [
                {
                    key: "index",
                    label: "#",
                },
                {
                    key: "icon",
                    label: "Icon",
                },
                {
                    key: "title",
                    label: "Title",
                },
                {
                    key: "score",
                    label: "Score",
                },
                {
                    key: "max_installs",
                    label: "Installs",
                    sortable: true,
                },
                {
                    key: "account",
                    label: "Account"
                },
                {
                    key: "playstoreUrl",
                    label: "Details",
                },
            ],
            optionsCountries: [],
            optionsChart: [
                { value: "topselling_free", text: "Free" },
                { value: "topselling_new_free", text: "New Free" },
            ],
            optionsType: [
                { value: null, text: "All" },
                { value: "GAME", text: "Games" },
                { value: "APPLICATION", text: "Applications" },
            ],
            optionsCategories: [
                { value: "OVERALL", text: "Overall" },
            ],
            chartSelected: "topselling_free",
            CategorySelected: "OVERALL",
            typeSelected: null,
            chartSelectedText: "New Free",
            countryCode: "ma",
            countryName: "Morocco",
            date: new Date(),
            minDate: new Date(),
            maxDate: new Date(),
            updated_At: null,
            apps: [],
            items: [],
            counter: 0,
            result: true,
            progress: true,
            isDatabase: false,
        };
    },
    watch: {
        countryCode(value) {
            this.progress = true;
            this.countryName = this.optionsCountries.find(
                (option) => option.value === this.countryCode
            ).text;

            this.getApps();
        },
        typeSelected() {
            if (this.typeSelected === "APPLICATION") {
                this.CategorySelected = "OVERALL";
                this.optionsCategories = [
                    { value: "OVERALL", text: "Overall" },
                    { value: "APPLICATION", text: "Application" },
                    { value: "ENTERTAINMENT", text: "Entertainment" },
                    { value: "TOOLS", text: "Tools" },
                    { value: "EDUCATION", text: "Education" },
                    { value: "MUSIC_AND_AUDIO", text: "Music & Audio" },
                    { value: "VIDEO_PLAYERS", text: "Video Players & Editors" },
                ]
            } else if (this.typeSelected === "GAME") {
                this.CategorySelected = "GAME";
                this.optionsCategories = [
                    { value: "GAME", text: "Overall" },
                    { value: "GAME_ACTION", text: "Games Action" },
                    { value: "GAME_CASUAL", text: "Games Casual" },
                    { value: "GAME_SIMULATION", text: "Games Simulation" },
                    { value: "GAME_ARCADE", text: "Games Arcade" },
                    { value: "GAME_PUZZLE", text: "Games Puzzle" },
                ]
            } else {
                this.CategorySelected = "OVERALL";
                this.optionsCategories = [
                    { value: "OVERALL", text: "Overall" },
                ]
            }
            this.getApps();
            this.getMinDate();
        },
        chartSelected() {
            this.chartSelectedText = this.optionsChart.find(
                (option) => option.value === this.chartSelected
            ).text;

            if (this.CategorySelected.includes("GAME")) {
                this.CategorySelected = "GAME";
            } else {
                this.CategorySelected = "OVERALL"
            }
            this.getApps();
            this.getMinDate();
        },
        CategorySelected() {
            this.getApps();
            this.getMinDate();
        },
        date(value) {
            this.getApps();
        },
    },

    mounted() {
        this.getApps();
        this.getMinDate();
        this.getCountries();
    },
    methods: {
        getCountries() {
            axios
                .get("/countries")
                .then((response) => {
                    this.optionsCountries = [];
                    response.data.countries.forEach((c) => {
                        this.optionsCountries.push({
                            value: c.code,
                            text: c.name,
                        });
                    });
                })
                .catch(console.error);
        },
        getApps() {
            this.items = [];
            this.totalRows = 0;
            this.progress = true;
            axios.get(`/get-top-apps-new?chart=${this.chartSelected}&category=${this.CategorySelected}&country=${this.countryCode}`)
            .then((response) => response.data)
            .then((data) => {
                if (data.status == "Unauthorized") {
                    removeLocalStorageItems();
                    this.$router.push({ name: "auth-login" });
                }

                this.progress = false;
                if (data && data.data && data.data.topApps && data.data.topApps.length > 0) {
                    this.updated_At = data.data.updated_at
                    this.isDatabase = true;
                    let apps = data.data.topApps.sort((a, b) => {
                        return a.position - b.position;
                    });
                    this.displayTableContent(apps.map(item=>({...item,error:false})));
                }
            })
            .catch(console.error);
        },

        displayTableContent(results) {
            this.apps = results;
            this.items = this.addIndexToTable(this.apps);
            this.totalRows = this.items.length;
        },

        addIndexToTable(table) {
            const t = table.map((item, index) => {
                item.index = index + 1;

                return item;
            });

            return t;
        },

        getMinDate() {
            axios
                .post("/get-market-apps-min-date", {
                    chart: this.chartSelected,
                    category: this.CategorySelected
                        ? this.CategorySelected
                        : "OVERALL",
                })
                .then(({ data }) => {
                    if (!(data.min_date === null)) {
                        this.minDate = data.min_date;
                    }
                });
        },
    },

    beforeRouteEnter(to, from, next) {
        const token = JSON.parse(localStorage.getItem("accessToken"));
        axios
            .post("/check-user-login", {
                token,
            })
            .then(({ data }) => {
                if (data.status === "success") {
                    next(true);
                } else {
                    removeLocalStorageItems();
                    next({ name: "auth-login" });
                }
            });
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
